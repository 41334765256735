@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-color: #000300; */
}
.main1{
  background: url('./assets/background.png');
}
.custom-width {
  width: 66vw; /* Tăng chiều rộng lên 66% chiều rộng của màn hình */
}
.powerby{
  padding-left: 10px;
  padding-top: 16px;
}
.footer-left{
  width: 50%;
}
.footer-right{
  width: 50%;
}
.footer-body{
  display: flex;
}
.icon-f-1{
  width: 25px;
}
.icon-f-2{
  width: 20px;
  height: 20px;
}
.socialbetween > a{
  margin-right: 20px;
}
.socialbetween1 > a{
font-size: 14px;
margin-right: 15px;
}
.btn-staking{
  background: rgb(12, 193, 224);
  border-radius: 13px;
  padding: 4px 15px;
  text-align: center;
  color: #fff;
  display: inline-block;
}
.linkbtn{
  color: rgb(31, 199, 212);
}
.copyright{
  font-size: 14px;
  width: 100%;
  float: left;
}
@media (max-width: 640px) {
  .footer-left{
    width: 100%;
  }
  .footer-right{
    width: 100%;
  }
  .footer-body{
    display: grid;
  }
  .socialbetween-all{
    width: 50%;
    display: block;
    float: left;
  }
  .socialbetween-all a{
    margin-bottom: 15px;
  }
  .footer-right-flex{
    display: block;
  }
  .footer-right-1{
    /* width: 50%;
    float: left; */
  }
  .main1{
    padding-left: 10px;
    padding-right: 10px;
  }
  .h2-title-1{
    margin-top: 30px;
  }
}